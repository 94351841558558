<template>
  <div>
    <!-- 选择资源组件 添加资源组件 -->
    <el-button type="primary" @click="dialogVisible = true">
      <span v-if="onlyupload === false">点击添加</span>
      <span v-else>上传资源</span>
    </el-button>
    <!-- 添加文档视频对话框 -->
    <div class="mydialog">
      <el-dialog :visible.sync="dialogVisible" width="942px" :show-close="false" top="2vh" append-to-body
        @open="resethanshu" class="dialog-box">
        <div class="dialog_content">
          <div class="title">
            <div class="title_word">添加文档/视频
              <span class="temp_class_span_div">-kkk</span>
            </div>
            <div :class="['point_one', 'cursor', data1 == 'one' ? 'active' : '']" @click="changepart('one')"
              v-if="onlyupload === false">
              我的资源
            </div>
            <div :class="['point_one', 'cursor', data1 == 'two' ? 'active' : '']" @click="changepart('two')">
              上传资源
            </div>
          </div>
          <div class="hengxian"></div>
          <div class="main" v-if="data1 == 'one'">
            <el-form ref="reqObj" :model="reqObj">
              <div class="fc">
                <el-form-item label="" class="form-item ml30 mr20">
                  <el-select clearable v-model="reqObj.sources_id" placeholder="请选择资源类型" size="small"
                    @change="handleFilter()">
                    <el-option v-for="(item, index) in resourcetypesOption" :key="index" :label="item.name"
                      :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="" class="form-item">
                  <el-input placeholder="输入资源名称查询" clearable v-model="reqObj.title" @keyup.enter.native="handleFilter()"
                    @clear="handleFilter()" style="width:240px" size="small">
                    <i slot="append" class="el-icon-search cursor" @click="handleFilter()"></i>
                  </el-input>
                </el-form-item>
              </div>
            </el-form>
            <div class="main_all">
              <div class="main_block cursor" v-for="(item, index) in all_resource" :key="index"
                @click="handleClick(item, index)">
                <div class="images">
                  <!-- <img :src="item.mime | fileTypeSmall " alt=""> -->
                  <img :src="onShowImages(item.mime)" alt="" />
                  <span>{{ item.sources_name || "-" }}</span>
                </div>
                <div class="block_word">
                  <p> {{ item.title || "-" }} </p>
                  <p style="
                      white-space: pre-line;
                      overflow: hidden;
                      -webkit-line-clamp: 1;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-box-orient: vertical;
                    ">
                    {{ item.pharse_name || "-" }}
                    {{ item.subject_name || "-" }} /
                    {{ item.grade_name || "-" }}
                  </p>
                  <div v-if="false" class="block_gion">
                    <div class="block_gion_one">{{ item.area }}</div>
                    <div class="block_gion_one">{{ item.year }}</div>
                  </div>
                  <div class="block_time">
                    <span>上传时间：{{ item.create_time || "-" }}</span>
                    <span>收藏次数：{{ item.collect_count || "0" }}</span>
                    <span>文件大小：{{ item.size | fileSizeFilter }}</span>
                  </div>
                </div>
                <!-- 打钩样式 -->
                <!-- <div class="outBox">
                  <div class="block_sign" :class="{ active: ifselected(item.id) }">
                    <i class="el-icon-check" v-if="ifselected(item.id)"></i>
                  </div>
                </div> -->
                <div class="outBox">
                  <div class="block_sign" :class="{ active: item.select }">
                    <i class="el-icon-check" v-if="item.select"></i>
                  </div>
                </div>
                <!-- end -->
              </div>
              <CommonEmpty v-if="!all_resource.length" hVal="100%"></CommonEmpty>
            </div>

            <div class="fsbc pt40 pb70 pl30 pr30">
              <div v-if="total != 0" class="pagin mt0">
                <el-pagination background :current-page="page" :page-size="limit" layout="prev, pager, next, jumper"
                  :total="total" @current-change="handleCurrentChange"></el-pagination>
              </div>
              <div class="save">
                <div class="save_one cursor" @click="onePartCancel">取消</div>
                <div class="save_two cursor" @click="onePartSave">保存</div>
              </div>
            </div>
          </div>

          <!-- 上传资源模块 -->
          <div class="main_block_two" v-if="data1 == 'two'">
            <div class="main_center">
              <el-form ref="form" :model="form" label-width="120px" :rules="rules1" :inline="true">
                <div>
                  <el-form-item label="上传文件：" prop="resource_id" class="div_row_box">
                    <div class="upload_div_box">
                      <dc-upload class="avatar-uploader" ref="upload" :dcAutoUpload="true" :show-file-list="false"
                        dcdom="ceshi99" :dcConfig="dcConfig" @dc-success="dcSuccess" @dc-progress="dcProgressTrue = false"
                        v-if="dcConfig.key != '' && dcConfig.notify_url != ''"
                        accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf,.txt,.flv,.mp4,.mpg,.gif,.jpg,.jpeg,.png">
                        <el-button type="primary">选择文件</el-button>
                      </dc-upload>
                      <p>{{ form.name2 }}</p>
                    </div>
                  </el-form-item>
                </div>
                <el-form-item label="资源名称：" prop="name" class="div_row_box">
                  <el-input placeholder="默认为文件名" class="two" v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="资源类型：" prop="resourcetypesdata" class="div_row_box">
                  <el-select v-model="form.resourcetypesdata" placeholder="请选择资源类型" class="two">
                    <el-option v-for="(item, index) in resourcetypesOption" :key="index" :label="item.name"
                      :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="学段学科：" class="div_row_box">
                  <div class="three_select">
                    <el-form-item label="">
                      <el-select v-model="form.stagedata" placeholder="请选择学段" @change="handleStageChange">
                        <el-option v-for="(item, index) in stageOption" :key="index" :label="item.name"
                          :value="item.id"></el-option>
                      </el-select>
                    </el-form-item>

                    <el-form-item label="">
                      <el-select placeholder="请选择学科" v-model="form.subjectdata" @change="handleSubjectChange">
                        <el-option v-for="(item, index) in subjectOption" :key="index" :label="item.subjectName"
                          :value="item.subjectId">
                        </el-option>
                      </el-select>
                    </el-form-item>

                    <el-form-item label="">
                      <el-select v-model="form.gradedata" placeholder="请选择年级" @change="handleGradeChange">
                        <el-option v-for="(item, index) in gradeOption" :key="index" :label="item.gradeName"
                          :value="item.gradeId"></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </el-form-item>
                <el-form-item v-if="false" label="适用教材：">
                  <div class="three_select">
                    <el-select v-model="form.versiondata" placeholder="请选择版本" class="three" @change="handleVersionChange">
                      <el-option v-for="(item, index) in versionOption" :key="index" :label="item.Name"
                        :value="item.Id"></el-option>
                    </el-select>
                    <el-select v-model="form.chapterdata" placeholder="请选择章节" class="three">
                      <el-option v-for="(item, index) in chapterOption" :key="index" :label="item.title"
                        :value="item.oid"></el-option>
                    </el-select>
                    <!-- <el-select
                      v-model="value"
                      placeholder="请选择节"
                      class="three"
                    >
                      <el-option></el-option>
                    </el-select> -->
                  </div>
                </el-form-item>
                <el-form-item v-if="false" label="地区年份：">
                  <div class="three_select">
                    <el-select v-model="form.areadata" placeholder="请选择地区" class="three">
                      <el-option v-for="(item, index) in areaOption" :key="index" :label="item.area"
                        :value="item.area"></el-option>
                    </el-select>
                    <el-select v-model="form.yeardata" placeholder="请选择年份" class="three">
                      <el-option v-for="(item, index) in yearOption" :key="index" :label="item.year"
                        :value="item.year"></el-option>
                    </el-select>
                  </div>
                </el-form-item>
              </el-form>

              <div class="five"></div>
              <div class="six">
                <p>注意事项：</p>
                <p>
                  1.文件支持格式：
                  doc、docx、xls、xlsx、ppt、pptx、pdf、txt、flv、mp4、mpg、gif、jpg、jpeg、png
                </p>
                <p>2.单个文件大小：≤1G </p>
              </div>
              <div class="save pb40">
                <div class="save_one cursor" @click="onePartCancel">取消</div>
                <div class="save_two cursor" @click="twopartSave">保存</div>
              </div>
            </div>
          </div>

        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import NextListingofflineVue from "../../views/teachingGroup/GroupCreate/InForChange/NextListingoffline.vue";
import { onShowImages } from "@/utils/utils";
export default {
  props: {
    //是否是单选
    ifsingle: Boolean,
    //是否只显示上传资源区域
    onlyupload: {
      type: Boolean,
      default: false,
    },
    // 活动id
    activity_id: {},
    // 环节id
    link_id: {},
    selectValue: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      rules1: {
        resource_id: [{ required: true, message: '请上传文件', trigger: 'blur' }],
        name: [{ required: true, message: '请输入文件名', trigger: 'blur' }],
        resourcetypesdata: [{ required: true, message: '请选择资源类型', trigger: 'change' }],
        type: [{ required: true, message: '请选择文件夹', trigger: 'change' }],
        stagedata: [{ required: true, message: '请选择学段', trigger: 'change' }],
        subjectdata: [{ required: true, message: '请选择学科', trigger: 'change' }],
        gradedata: [{ required: true, message: '请选择年级', trigger: 'change' }],
      },
      data1: "one",
      // 添加文档视频对话框的显示与隐藏
      dialogVisible: false,
      // 总资源数据
      all_resource: [],
      // 选中的资源id
      select_resource: [],
      // 选中的资源完整信息
      all_select_resource: [],
      // 每页条数
      limit: 10,
      // 当前页数
      page: 1,
      //总数据数目
      total: 0,
      //金山云上传配置
      dcConfig: {
        key: "", // 后台域名对应key
        notify_url: "", // 上传接口地址
      },
      // 上传资源表单
      form: {
        name: "",
        name2: "",
        resource_id: "",
        // resource_name: "",
        stagedata: "",
        subjectdata: "",
        gradedata: "",
        resourcetypesdata: "",
        versiondata: "",
        chapterdata: "",
        areadata: "",
        yeardata: "",
      },
      reqObj: {
        sources_id: "",
        title: "",
      },
      //学段数据选项
      stageOption: [],
      //学科数据选项
      subjectOption: [],
      // 年级数据选项
      gradeOption: [],
      //资源类型数据选项
      resourcetypesOption: [],
      // 版本列表数据选项
      versionOption: [],
      // 章节列表数据选项
      chapterOption: [],
      // 地区数据选项
      areaOption: [],
      // 年份数据选项
      yearOption: [],
    };
  },
  computed: {},
  created() {
    this.setSelectValue();
    this.init();
    let token = localStorage.getItem("token");
    if (token) {
      this._getAddress();
    }
    this.getStage();
    this.getresourceTypes();
  },
  methods: {
    onShowImages,
    //初始化函数
    init() {
      if (this.onlyupload === false) {
        this.data1 = "one";
      } else {
        this.data1 = "two";
      }
    },
    //
    //切换不同区域函数
    changepart(str) {
      this.data1 = str;
    },
    // 判断选中当前资源函数
    ifselected(id) {
      for (var i = 0; i < this.select_resource.length; i++) {
        if (id == this.select_resource[i]) {
          return true;
        }
      }
    },
    // 处理当前所点击资源函数
    handleClick(e, index) {
      this.all_resource[index].select = !this.all_resource[index].select;
      if (this.select_resource.length > 0) {

      }

      let next = true;
      this.select_resource.forEach((item, index) => {
        if (item.id == e.id) {
          this.select_resource.splice(index, 1);
          next = false;
        }
      });
      if (next) {
        this.select_resource.push({
          id: e.id,
          title: e.title,
          url: e.url,
        });
      }
      // if (this.ifsingle == false) {
      //   //    多选
      //   var a = this.select_resource.indexOf(id);
      //   // console.log(a)

      //   if (a == -1) {
      //     // 不存在
      //     this.select_resource.push(id);
      //     this.all_select_resource.push(this.all_resource[index]);
      //   } else {
      //     this.select_resource.splice(a, 1);
      //     this.all_select_resource.splice(a, 1);
      //   }
      // } else if (this.ifsingle == true) {
      //   // 单选
      //   // console.log("true");
      //   this.select_resource = [id];
      //   this.all_select_resource = [this.all_resource[index]];
      // }
    },
    // 第一个区域保存函数
    onePartSave() {
      console.log(this.select_resource)
      this.$emit("ouput-value", this.select_resource);
      this.dialogVisible = false;
    },
    // 第一个区域取消函数
    onePartCancel() {
      this.dialogVisible = false;
    },
    handleFilter() {
      this.page = 1;
      this.getAllResource();
    },
    // 请求后台获取我上传的数据
    getAllResource() {
      this.all_resource = [];
      var obj = {
        params: {
          limit: this.limit,
          page: this.page,
          ...this.reqObj
        },
      };
      this.$axios.get("resource/get_my_found_list", obj).then((res) => {
        var resdata = res.data;
        this.total = resdata.data.total;
        if (Array.isArray(resdata.data.data)) {
          resdata.data.data.forEach((item) => {
            item.select = false;
            this.select_resource.forEach((item1) => {
              if (item1.id == item.id) {
                item.select = true;
              }
            });
          });
          this.all_resource = resdata.data.data;
        } else {
          this.$message.info(resdata.data.data);
        }
      });
    },
    // 改变页数函数
    handleCurrentChange(val) {
      this.page = val;
      this.getAllResource();
    },
    // 打开dialog触发的函数
    resethanshu() {
      //   this.select_resource = [];
      //   this.all_select_resource = [];
      this.page = 1;
      this.getAllResource();
    },
    // 获取金山云配置
    async _getAddress() {
      let res = await this.$axios.get("/k3sSetting");
      this.dcConfig.key = res.data.data.key;
      this.dcConfig.notify_url = res.data.data.callBack;
    },
    // 上传成功后的回调
    async dcSuccess(data) {
      console.log(data);
      if (!data.upload) return;
      var resData = data.upload.CompleteMultipartUploadResult;
      resData.ETag = resData.ETag.replace(/"/g, "");
      this.form.name2 = data.filename;
      // this.form.name = data.filename;
      this.form.name = this.splitFileName(data.filename);
      this.form.url = resData.Location;
      let res = await this.$axios.get("/etagChangeId", {
        params: {
          etag: resData.ETag,
        },
      });
      this.form.resource_id = res.data.data.id;
    },
    // 请求后台获取学段数据
    getStage() {
      this.$axios.get("stage/stage_list").then((res) => {
        this.stageOption = res.data.data;
      });
    },
    // 请求后台获取学科数据
    getSubject() {
      this.$axios
        .get("subject/list", {
          params: {
            pharseid: this.form.stagedata,
          },
        })
        .then((res) => {
          var resdata = res.data;
          this.subjectOption = resdata.data;
        });
    },
    // 学段改变时触发的函数
    handleStageChange() {
      this.form.subjectdata = "";
      this.form.gradedata = "";
      this.form.versiondata = "";
      this.form.chapterdata = "";
      this.form.areadata = "";
      this.form.yeardata = "";
      this.getSubject();
    },
    // 请求后台获取年级数据
    getGrade() {
      var obj = {
        params: {
          pharseid: this.form.stagedata,
          subjectId: this.form.subjectdata,
        },
      };
      this.$axios.get("grade/list", obj).then((res) => {
        var resdata = res.data;
        this.gradeOption = resdata.data;
      });
    },
    //学科改变时触发的函数
    handleSubjectChange() {
      this.form.gradedata = "";
      this.form.versiondata = "";
      this.form.chapterdata = "";
      this.form.areadata = "";
      this.form.yeardata = "";
      this.getGrade();
      this.getArea();
      this.getYear();
    },
    // 请求后台获取资源类型
    getresourceTypes() {
      this.$axios.get("resourceTypes").then((res) => {
        this.resourcetypesOption = res.data.data;
      });
    },
    // 请求后台获取版本
    getVersion() {
      var obj = {
        params: {
          pharseid: this.form.stagedata,
          subjectId: this.form.subjectdata,
          gradeId: this.form.gradedata,
        },
      };
      this.$axios.get("versions/list", obj).then((res) => {
        var resdata = res.data;
        this.versionOption = resdata.data;
      });
    },
    // 年级改变触发的函数
    handleGradeChange() {
      this.form.versiondata = "";
      this.form.chapterdata = "";
      this.getVersion();
    },
    // 请求后台获取章节
    getChapter() {
      var obj = {
        params: {
          pharseid: this.form.stagedata,
          subjectid: this.form.subjectdata,
          gradeid: this.form.gradedata,
          editionid: this.form.versiondata,
        },
      };
      this.$axios.get("chapter/list", obj).then((res) => {
        var resdata = res.data;
        this.chapterOption = resdata.data;
      });
    },
    // 版本改变触发函数
    handleVersionChange() {
      this.form.chapterdata = "";
      this.getChapter();
    },
    // 请求后台获取地区
    getArea() {
      var pharse = "";
      this.stageOption.forEach((item) => {
        if (item.id == this.form.stagedata) {
          pharse = item.name;
        }
      });
      var subject = "";
      this.subjectOption.forEach((item) => {
        if (item.subjectId == this.form.subjectdata) {
          subject = item.subjectName;
        }
      });
      var obj = {
        params: {
          pharse: pharse,
          subject: subject,
        },
      };
      this.$axios.get("resourceArea", obj).then((res) => {
        var resdata = res.data;
        this.areaOption = resdata.data;
      });
    },
    // 请求后台获取年份
    getYear() {
      var pharse = "";
      this.stageOption.forEach((item) => {
        if (item.id == this.form.stagedata) {
          pharse = item.name;
        }
      });
      var subject = "";
      this.subjectOption.forEach((item) => {
        if (item.subjectId == this.form.subjectdata) {
          subject = item.subjectName;
        }
      });
      var obj = {
        params: {
          pharse: pharse,
          subject: subject,
        },
      };
      this.$axios.get("resourceYear", obj).then((res) => {
        var resdata = res.data;
        this.yearOption = resdata.data;
        let array = [];

        for (let i = 0; i < this.yearOption.length; i++) {
          if (
            this.yearOption[i].year != "不限" &&
            this.yearOption[i].year != "更早"
          ) {
            array.push(this.yearOption[i]);
          }
        }
        this.yearOption = array;
      });
    },
    // 第二部分区域保存函数
    twopartSave() {
      this.$refs['form'].validate((valid) => {
        if (!valid) {
          return false;
        };
        var ids = [];
        ids.push(this.form.resource_id);
        var titles = [];
        titles.push(this.form.name);
        var sources = [];
        sources.push(this.form.resourcetypesdata);
        if (this.onlyupload === false) {
          // var obj = {
          //   ids: ids.toString(),
          //   titles: titles.toString(),
          //   sources: sources.toString(),
          //   grade: this.form.gradedata.toString(),
          //   edition: this.form.versiondata.toString(),
          //   subject: this.form.subjectdata.toString(),
          //   year: this.form.yeardata,
          //   area: this.form.areadata,
          //   chapter_id: this.form.chapterdata.toString(),
          //   pharse: this.form.stagedata.toString(),
          // };
          let obj = {
            title: titles.toString(),
            sources: sources.toString(),
            grade: this.form.gradedata.toString(),
            subject: this.form.subjectdata.toString(),
            pharse: this.form.stagedata.toString(),
            resource_id: this.form.resource_id,
            url: this.form.url,
          };
          this.$axios.post("upload/library", obj).then((res) => {
            this.$message({
              type: "success",
              message: "上传成功",
            });
            this.getAllResource();
            this.data1 = "one";
          });
        } else {
          var obj = {
            resource_id: this.form.resource_id,
            name: this.form.name,
            source: this.form.resourcetypesdata,
            grade: this.form.gradedata,
            edition: this.form.versiondata,
            subject: this.form.subjectdata,
            year: this.form.yeardata,
            area: this.form.areadata,
            chapter_id: this.form.chapterdata,
            pharse: this.form.stagedata,
            activity_id: this.activity_id,
            link_id: this.link_id,
          };
          console.log("fafa", obj);
          this.$axios
            .post("ActivityUserResource/insertResource", obj)
            .then((res) => {
              this.$emit("ouput-value");
              this.form = {
                name: "",
                resource_id: "",
                // resource_name: "",
                stagedata: "",
                subjectdata: "",
                gradedata: "",
                resourcetypesdata: "",
                versiondata: "",
                chapterdata: "",
                areadata: "",
                yeardata: "",
              };
              this.dialogVisible = false;
            });
        }
      })
    },
    // 获取不带后缀名的字符串函数
    splitFileName(text) {
      var pattern = /\.{1}[a-z]{1,}$/;
      if (pattern.exec(text) !== null) {
        return text.slice(0, pattern.exec(text).index);
      } else {
        return text;
      }
    },
    //设置默认选中值
    setSelectValue() {
      if (this.selectValue.length > 0) {
        let list = JSON.parse(JSON.stringify(this.selectValue));
        list.forEach((item) => {
          if (item.hasOwnProperty("name")) {
            item.title = item.name;
            delete item.name;
          }
        });
        this.select_resource = list;
      }
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.getAllResource();
      }
    },
    selectValue() {
      this.setSelectValue();
    }
  },
};
</script>

<style lang="less" scoped>
.upload_div_box {
  /deep/ .el-progress-circle {
    width: 40px !important;
    height: 40px !important;
  }

  /deep/ .el-progress {
    left: 120px;
  }
}

.div_row_box {
  display: block;
}

.dialog_content {
  overflow: hidden;

  .title {
    display: flex;
    padding: 16px 0px 16px 30px;
    align-items: center;

    .title_word {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      // margin-right: 26px;
    }

    .point_one {
      width: 100px;
      height: 40px;
      background: #E6F0FF;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 26px;
      font-size: 16px;
      color: #606266;
    }

    .active {
      color: #fff;
      background: #3B90FF;
    }
  }

  .hengxian {
    width: 100%;
    height: 1px;
    background: #DEDEDE;
  }

  .main {
    margin-top: 20px;

    // margin-left: 40px;
    .main_one {
      display: flex;
      align-items: center;
      margin-bottom: 26px;

      p:nth-child(1) {
        margin-left: 15px;
      }

      p:nth-child(3) {
        margin-left: 81px;
      }

      p:nth-child(5) {
        margin-left: 49px;
      }

      p:nth-child(7) {
        margin-left: 39px;
      }
    }

    .main_two {
      display: flex;
      align-items: center;
      margin-bottom: 26px;

      p:nth-child(1) {
        margin-left: 15px;
      }

      p:nth-child(3) {
        margin-left: 81px;
      }
    }

    .main_three {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      p:nth-child(3) {
        margin-left: 50px;
      }

      .reset_button {
        margin-left: 444px;
        margin-right: 20px;
        width: 82px;
        height: 34px;
        background: #f7f7f7;
        border: 1px solid #ececec;
        border-radius: 6px;
        color: #333333;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .sousuo_button {
        width: 82px;
        height: 34px;
        background: #ff8201;
        border-radius: 6px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .main_all {
      height: 380px;
      padding: 0 30px;
      overflow-y: scroll;
      // overflow: auto;

      //滚动条样式
      &::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 10px;
        /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
      }

      &::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 10px;
        background-color: skyblue;
        background-image: -webkit-linear-gradient(45deg,
            rgba(255, 255, 255, 0.2) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, 0.2) 50%,
            rgba(255, 255, 255, 0.2) 75%,
            transparent 75%,
            transparent);
      }

      &::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #ededed;
        border-radius: 10px;
      }

      //滚动条样式改动
      .main_block {
        //width: 864px;
        width: 100%;
        height: 120px;
        border-top: 1px dashed #DAE7ED;
        //border: 1px solid #ececec;
        //border-radius: 6px;
        display: flex;

        //margin-bottom: 20px;
        .images {
          width: 100px;
          // height: 100px;
          //border: 1px solid #ececec;
          border-radius: 6px;
          margin-top: 25px;
          margin-left: 20px;
          //margin-right: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            //margin-top: 20px;
            margin-bottom: 15px;
            // width: 160px;
            // height: 160px;
            width: 50%;
          }
        }

        .block_word {
          flex: 1;
          overflow: hidden;
          white-space: pre-line;
          overflow: hidden;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;

          p:nth-child(1) {
            margin-top: 22px;
            margin-bottom: 8px;
            font-size: 16px;
            color: #333;
            font-weight: bold;
            width: 494px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          p:nth-child(2) {
            font-size: 14px;
            color: #666;
            margin-bottom: 8px;
          }

          .block_gion {
            display: flex;
            margin-bottom: 14px;

            .block_gion_one {
              width: 69px;
              height: 24px;
              background: #ffffff;
              border: 1px solid #ff8201;
              border-radius: 4px;
              color: #ff8201;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 12px;
            }
          }

          .block_time {
            font-size: 14px;
            color: #666;

            span {
              margin-right: 30px;
            }
          }
        }

        // 这里是打钩样式
        .outBox {
          margin: 0 30px;

          .block_sign {
            margin-top: 40px;
            // margin-left: 110px;
            width: 34px;
            height: 34px;
            background: #ffffff;
            border: 1px solid #ececec;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .active {
            background: #3489fe;
            color: #ffffff;
          }
        }
      }
    }
  }

  .main_block_two {
    margin-top: 88px;
    display: flex;
    justify-content: center;

    .main_center {
      margin-top: -60px;

      .one {
        width: 134px;
        height: 34px;
        background: #3489fe;
        border-radius: 4px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .two {
        width: 439px;
      }

      // .three {
      //   width: 220px;
      //   // float: left;
      // }
      .three_select {
        display: flex;

        .three {
          // width: 220px;
          // float: left;
        }
      }

      .four {
        margin-left: 120px;
        margin-bottom: 37px;
      }

      .five {
        width: 770px;
        //  width: 775px;
        border-top: 1px dashed #dedede;
        margin-bottom: 30px;
        margin-left: 35px;
      }

      .six {
        margin-left: 35px;

        // margin-bottom:50px;
        p:nth-child(2) {
          color: #999999;
          margin-bottom: 20px;
          margin-top: 20px;
        }

        p:nth-child(3) {
          color: #999999;
        }
      }
    }
  }

  .save {
    display: flex;
    justify-content: center;

    .save_one {
      width: 120px;
      height: 40px;
      background: #f7f7f7;
      border: 1px solid #ececec;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      margin-right: 22px;
    }

    .save_two {
      width: 120px;
      height: 40px;
      background: #ff8201;
      border-radius: 6px;
      font-weight: 700;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.dialog-box {
  /deep/.el-dialog__header {
    display: none;
  }

  /deep/.el-dialog__body {
    padding: 0;
  }
}

.form-item {
  /deep/.el-input__inner {
    background: #ECF0F6;

    &::-webkit-input-placeholder {
      color: #999999;
    }
  }

  /deep/.el-form-item__content {
    line-height: initial;
  }
}

.el-form-item {
  margin-bottom: 20px;
}
</style>